@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss"; 

// Colors
$red-color: #c20000;  

.bg-red {
	background-color: $red-color; 
}

.bg-black {
	background-color: #000; 
}

.bg-gray-light {
	background-color: #fafaf9;
}

.color-white {
	color: #fff;
}

.c-red {
	color: $red-color;
}

a[href="#top"] {
	padding: 6px;
	position: fixed;
	top: 90%;
	right: 15px;
	display: none;
	font-size: 20px;
	background-color: #c20000;
	border-radius: 50%;
	text-align: center;
	line-height: 20px;
    color: #fff;
    opacity: 0.6;
    z-index: 99999;
    -webkit-transition: all 0.3s ease-out;  
            transition: all 0.3s ease-out; 
    &:hover, &:focus {
        text-decoration:none;
        opacity: 1;
    }
}

.swiper-container.gallery-top {
    height: 80%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    .swiper-slide {
        background-size: cover;
        background-position: center;
        height: 340px;
    }
}
.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100px;
    opacity: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.gallery-thumbs .swiper-slide-active {
    opacity: 1;
}

.swiper-container.swiper-container-full {
    width: 100%;
    height: 100%;
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        background-repeat: none; 
        background-size: cover;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}


* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    outline: none;
}

body {
	font-family: 'Raleway', sans-serif;
	letter-spacing: 1px;
	font-size: 16px;
	font-weight: 400;
	color: #180000;
	position: relative;
}

header {
	position: absolute; 
	width: 100%;	
	z-index: 9;
	.logo {
		max-height: 60px;
	}
}

.btn-square-4 {
	display: inline-block;
    border: solid #dadada 6px;
    padding: 6px 8px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
	&:hover, &:focus {
		border-color: #ecc1c1;
		color: #fff;
		text-decoration: none;
	}
}

h1, h2, h3, h4, h4 {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
}

.navigation {
	ul {
		margin: 0; 
		padding: 0; 
		list-style: none;
		text-align: center;
		li {
			display: table-cell;
			vertical-align: middle; 
			width: 1%;
			a {
				font-size: 14px;
				color: #fff;
				display: block;
				transition: 0.2s ease;
				padding: 10px 0;
				margin: 0 30px;
				text-decoration: none;
				border-bottom: 3px solid transparent;
				&:hover {
					text-decoration: none; 
					color: #fff;
					border-bottom-color: rgba(255,255,255,0.8);
					padding: 6px 0; 
				}
			}
		}
		li.active {
			a {
				border-bottom: 3px solid rgba(255,255,255,0.8);
			}
		}
	}
}

.no-banner {
	background-color: #c20000;
    min-height: 80px;
}

body.intern > header {
	position: relative; 
	padding-bottom: 15px;

	.btn-square-4 {
	    border: solid #ecc1c1 6px;
	    color: $red-color;
		&:hover, &:focus {
			border-color: #ecc1c1;
			color: $red-color;
			text-decoration: none;
		}
	}
}

body.intern {
	.navigation {
		ul {
			li {
				a {
					color: #111111;
					&:hover {
						border-color: rgba(17,17,17,0.8);
					}
				}
			}
			li.active {
				a {
					padding: 6px 0;
					border-bottom: 3px solid rgba(17,17,17,0.8);
				}
			}
		}
	}
}

.breadcrumb {
	background-color: transparent; 
	text-align: center; 
	margin-bottom: 60px;
	li {
		
		a {
			color: #111111;
			text-decoration: none;
		}

		span {
			color: $red-color; 
		}
	}
}

footer {
	font-size: 14px;
	.footer-navigation {
		ul {
			margin: 0; 
			padding: 0; 
			list-style: none;
			li {
				display: table-cell;
				vertical-align: middle; 
				width: 1%;
				a {
					font-size: 14px;
					color: #111111;
					display: block;
					padding: 0 5px;
					padding-top: 15px;
					&:hover {
						text-decoration: none; 
						color: #111111;
					}
				}
			}
		}
	}
}
.foot .navigation {
	ul {
		li {
			a {
				color: #111111;
			}
		}
	}
}

.banner-top {
	background-repeat: no-repeat;
	background-size: cover; 
	text-align: center; 
	color: #fff;
	height: 100vh;
	display: table;
	width: 100%;
	position: relative;
	h1 {
		font-size: 110px;
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: bold;
	}
	h2 {
		font-size: 80px;
	    margin-top: 0;
	    text-transform: uppercase;
	}
	p {
		font-size: 22px;
	}
	a.action {
		display: inline-block;
		color: #fff;
		border: solid 5px #fff;
		padding: 10px 50px;
		font-weight: 800;
		text-transform: uppercase;
		text-decoration: none;
		-webkit-transition: all 0.3s ease-out;  
		        transition: all 0.3s ease-out; 
		&:hover {
			background-color: #fff; 
			color: #111111;
		}
	}
	.view-more {
		display: block;
		position: absolute;
		bottom: 10px;
		z-index: 9999;
		opacity: .5;
		left: calc(50% - 25px);
		width: 50px;
		text-align: center;
		line-height: 40px;
		text-decoration: none;
		color: #fff;
		font-size: 40px;
		height: 50px;
		content: ''; 
		animation: down-up .5s alternate infinite;
		-webkit-animation: down-up .5s alternate infinite;
		-moz-animation: down-up .5s alternate infinite;
		transition: all .2s ease-out;
		-webkit-transition: all .2s ease-out;
		&:hover {
			opacity: 1;
			color: #fff;
			text-decoration: none;
		}
	}
	iframe {
		height: 100vh;
		width: 100%;
		z-index: 9;
	}
}

.mask-video {
	position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
	&:before {
		position: absolute; 
		content: ''; 
		background-color: rgba(0,0,0,0.5);
		width: 100%; 
		height: 100%; 
		top: 0;
		left: 0; 
	}
}

.p-r-zindex {
	position: relative; 
	z-index: 99; 
}


@-webkit-keyframes down-up {
    to {
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@-moz-keyframes down-up {
    to {
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@keyframes down-up {
    to {
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

.d-flex {
	display: flex; 
	align-items: center;
}

.d-table {
	display: table; 
	width: 100%;
}

.d-table-cell {
	display: table-cell; 
	vertical-align: middle; 
}

.section-title-red {
	color: $red-color;
	font-size: 46px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.section-title-white {
	color: #fff;
	font-size: 46px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.detail-title-white {
	position: relative; 
	z-index: 1;
	margin-bottom: 50px;
	margin-top: 0;
	padding-left: 30px; 
	padding-top: 30px; 
	padding-bottom: 30px; 
	left: -30px;

	span {
		position: absolute;
		border: solid 8px #fff;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		border-right: 0;
		width: 178px;
		&:after {
			position: absolute;
			content: '';
			border-right: solid 8px #fff;
			right: 0;
			z-index: 1;
			height: 20px;
			top: 0;
		}
		&:before {
			position: absolute;
			content: '';
			border-left: solid 8px #fff;
			right: 0;
			z-index: 1;
			height: 20px;
			bottom: 0;
		}

	}

}

.detail-title-red {
	position: relative; 
	z-index: 1;
	margin-bottom: 50px;

	&:after {
		position: absolute;
	    content: '';
	    background-color: #fff;
	    width: 30px;
	    height: 60px;
	    left: 122px;
	    top: -2px;
	    z-index: -1;
	}
	&:before {
		content: '';
		position: absolute;
		border: solid 10px #f2f2f2;
		width: 200px;
	    height: 110px;
	    top: -30px;
	    left: -58px;
		z-index: -1;
	}
}

.detail-segment-title {
	position: relative; 
	display: inline-block;

	&:after {
		position: absolute;
		content: '';
		background-color: #fff;
		width: 30px;
		height: 60px;
		left: 271px;
		top: -2px;
		z-index: -1;
	}
	&:before {
		content: '';
		position: absolute;
		border: solid 10px #f2f2f2;
		width: 250px;
		height: 110px;
		top: -30px;
		left: 40px;
		z-index: -1;
	}
}

.about-triangle {
	background-image: url('../img/h1.jpg'); 
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: cover; 
}

.box-notices {
	background-image: url('../img/notice.jpg');
	background-size: cover;
	height: 350px;
	position: relative;
	background-repeat: no-repeat;
	display: block;
	color: #111111;
	margin-bottom: 10px;
	-webkit-transition: all 0.3s ease-out;  
	        transition: all 0.3s ease-out; 
	&:hover, &:focus {
		color: #111111;
	}
	&:before {
		-webkit-transition: all 0.3s ease-out;  
		        transition: all 0.3s ease-out; 
		width: 100%; 
		height: 100%;
		opacity: 0;
		background-color: rgba(181, 11, 11, 0.8);
		content: ''; 
		position: absolute;  
	}
	&:after {
		content: ''; 
		position: absolute;  
		border-left: solid 8px #fff;
		width: calc(100% - 38px);
		height: 55%;
		top: 20px;
		left: 20px;
		opacity: 0;
		border-top: solid 8px #fff;
		border-bottom: solid 8px #fff;
	}
	&:hover,{
		span, &:before, &:after {
			opacity: 1;
		}
	}
	span {
		color: #fff;
	    text-transform: uppercase;
	    font-size: 24px;
	    display: block;
	    position: absolute;
	    right: 20px;
	    top: calc(50% - 70px);
	    font-weight: 600;
	    opacity: 0;
	    &:before {
	    	content: '';
	    	position: absolute;
	    	border-right: solid 8px #fff;
	    	top: -80px;
	    	width: 100%;
	    	height: 70px;
	    	right: -2px;
	    }
	    &:after {
	    	content: '';
	    	position: absolute;
	    	border-right: solid 8px #fff;
	    	top: 40px;
	    	height: 60px;
	    	right: -2px;
	    }
	}

	.content {
		background-color: #fafaf9;
		position: absolute;
		bottom: 0;
		padding: 26px;
		left: 0;
		width: 100%;
		p {
			font-size: 16px; 
			font-weight: 700;
			position: relative;
			margin-bottom: 20px;
			&:before {
				position: absolute; 
				content: ''; 
				width: 94px;
				border-bottom: solid 3px $red-color;
				background-color: $red-color; 
				left: 0;
				bottom: -10px;
			}
		}
		span {
			font-size: 14px;
		}
	}
}

.box-test {
	.conteudo {
		z-index: 999;
	}
	span.detalhe {
		opacity: 0;
		background-color: rgba(181, 11, 11, 0.7);
		width: 100%;
		height: 100%;
		display: block;
		color: #fff;
		text-align: right;
		position: relative;
		z-index: 0;
		&:after {
			position: absolute;
			content: '';
			border: solid 8px #fff;
			border-right: none;
			width: 90%;
			height: 58%;
			left: 20px;
			top: 20px;	
		}	
	}
	&:hover {
		span.detalhe {
			opacity: 1;
		}
	}
}

.show-notices {
	margin-bottom: 50px;
	position: relative;
	min-height: 500px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	.content-show {
		background-color: #fafaf9;
		padding: 30px;
		position: absolute;
		bottom: 0;
		width: 100%;
		h2 {
			margin: 0; 
			position: relative;
			margin-bottom: 20px;   
			font-size: 30px;
    		font-weight: 500;
			&:before {
				position: absolute;
				content: '';
				width: 94px;
				border-bottom: solid 3px $red-color;
				background-color: $red-color; 
				left: 0;
				bottom: -10px;
			}
		}
		small {
			display: block;
		}
	}
	span {
		text-align: center;
		display: block; 
		margin-top: 30px;
		margin-bottom: 30px; 
		font-size: 16px;
	}
}

.box-products {
	// background-image: url('../img/notice.jpg');
	background-size: cover;
	height: 310px;
	position: relative;
	background-repeat: no-repeat;
	display: block;
	color: #111111;
	margin-bottom: 10px;
	-webkit-transition: all 0.3s ease-out;  
	        transition: all 0.3s ease-out; 
	&:hover, &:focus {
		color: #111111;
	}
	&:before {
		-webkit-transition: all 0.3s ease-out;  
		        transition: all 0.3s ease-out; 
		width: 100%; 
		height: 100%;
		opacity: 0;
		background-color: rgba(181, 11, 11, 0.8);
		content: ''; 
		position: absolute;  
	}
	&:after {
		content: ''; 
		position: absolute;  
		border-left: solid 8px #fff;
		width: calc(100% - 38px);
		height: 55%;
		top: 20px;
		left: 20px;
		opacity: 0;
		border-top: solid 8px #fff;
		border-bottom: solid 8px #fff;
	}
	&:hover,{
		span, &:before, &:after {
			opacity: 1;
		}
	}
	span {
		color: #fff;
	    text-transform: uppercase;
	    font-size: 24px;
	    display: block;
	    position: absolute;
	    right: 20px;
	    top: calc(50% - 70px);
	    font-weight: 600;
	    opacity: 0;
	    &:before {
	    	content: '';
	    	position: absolute;
	    	border-right: solid 8px #fff;
	    	top: -65px;
	    	width: 100%;
	    	height: 60px;
	    	right: -2px;
	    }
	    &:after {
	    	content: '';
	    	position: absolute;
	    	border-right: solid 8px #fff;
	    	top: 40px;
	    	height: 60px;
	    	right: -2px;
	    }
	}

	.content {
		background-color: #fff;
		position: absolute;
		bottom: 0;
		padding: 26px;
		left: 0;
		width: 100%;
		text-align: center;
		p {
			font-size: 16px; 
			font-weight: 700;
			position: relative;
			margin-bottom: 20px;
		}
		span {
			font-size: 14px;
		}
	}
}

.share-post {
	margin-bottom: 50px;
	span {
		font-size: 14px; 
		font-weight: 600;
	}
}

.custom-pagination {
	li {
		a, span {
			color: $red-color;
			border: 0;
			font-size: 20px;
			padding: 6px 14px;
			&:hover {
				color: $red-color;
			}
		}
	}
	.active > span {
		background-color: $red-color;
		&:hover {
			background-color: #d43131;
		}
	}
}

.btn-red {
	background-color: $red-color;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	border: solid 2px $red-color;
	position: relative;
	display: inline-block;
	padding: 10px 50px;
	text-decoration: none;
	-webkit-transition: all 0.3s ease-out;  
	        transition: all 0.3s ease-out; 
	&:before {
		position: absolute;
	    content: '';
	    border: solid 3px #ffffff;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
	&:hover, &:focus {
		color: $red-color;
		text-decoration: none;
		background-color: transparent; 
	}
}

.btn-red-invert {
	background-color: #fff;
	font-size: 16px;
	font-weight: 700;
	color: $red-color;
	border: solid 2px $red-color;
	position: relative;
	display: inline-block;
	padding: 10px 50px;
	text-decoration: none;
	-webkit-transition: all 0.3s ease-out;  
	        transition: all 0.3s ease-out; 
	&:hover, &:focus {
		color: #fff;
		text-decoration: none;
		background-color: $red-color; 
	}
}

.facebook-section {
	p {
		font-size: 21px;
		margin-bottom: 0;
	}
	.link-facebook {
		color: #47619c;
		font-size: 22px;
		font-weight: 700;
	}
	.btn-open-facebook {
		font-size: 16px;
		font-weight: 700;
		color: $red-color; 
		border: solid 2px $red-color;
		padding: 20px 70px;
		text-decoration: none;
		display: inline-block;
		-webkit-transition: all 0.3s ease-out;  
		        transition: all 0.3s ease-out; 
		&:hover, &:focus {
			text-decoration: none;
			color: #fff; 
			background-color: $red-color;
		}
	}
}

.bg-contato-home {
	background-image: url('../img/bg-contato-black.jpg'); 
	background-repeat: no-repeat; 
	background-size: cover;

	img {
		margin-bottom: 40px;
	}

	h3 {
		text-transform: uppercase;
		font-size: 22px;
		color: $red-color;
		margin-bottom: 25px;
	}

	.mail-home {
		color: #fff; 
		display: block;
		word-break: break-all;
	}

	.icon-title-fale-conosco {
		position: relative; 
		padding-left: 65px;
		color: $red-color; 
		margin-bottom: 40px;
		font-size: 22px;
		text-transform: uppercase;
		&:before {
			position: absolute; 
			content: ''; 
			background-image: url('../img/icon-fale-conosco.png'); 
			width: 43px; 
			height: 35px;
			top: -14px;
			left: 0;
		}
	}

	.traco {
		position: relative; 
		&:before {
			position: absolute; 
			content: ''; 
			border-right: solid 1px #fff; 
			right: -40px;
			top: 0;
			height: 100%;
		}
	}
}

.form-home {
	label {
		display: block;
		font-weight: 400;
		font-size: 12px;
		margin-bottom: 8px;
	}
	input, textarea {
		background-color: transparent;
		border-radius: 0;
		border: solid 1px #fff;
		box-shadow: none;
		margin-bottom: 2px;
		color: #fff;
		font-size: 12px;

		&:focus {
			border-color: #fff;
			outline: 0;
			box-shadow: none;
		}
	}

	input {
		height: 45px;
		line-height: 45px;
	}

	button[type="submit"] {
		display: block;
		width: 100%;
		color: #fff;
		font-weight: 700;
		padding: 0;
		font-size: 16px;
		height: 45px;
		line-height: 45px;
		background-color: transparent;
		border: solid 1px #fff;
		box-shadow: none;
		-webkit-transition: all 0.3s ease-out;  
		transition: all 0.3s ease-out;  
		&:hover, &:focus {
			background-color: #fff;
			color: #111111;
		}
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #fff;
		font-size: 12px;
		font-weight: 400;
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: #fff;
		font-size: 12px;
		font-weight: 400;
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: #fff;
		font-size: 12px;
		font-weight: 400;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: #fff;
		font-size: 12px;
		font-weight: 400;
	}
}

.facebook-section {
	text-align: center;
}

.wrapper {
	display: grid;
	grid-gap: 10px;
	// grid-template-columns: 100px 100px 100px;
	background-color: #fff;
	color: #444;

	.box {
		background-color: $red-color;
		min-height: 230px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%; 
		height: 100%;
		grid-column: 2; 
		position: relative; 
		-webkit-transition: all 0.3s ease-out;  
		transition: all 0.3s ease-out; 
		&:first-child {
			grid-column: 1; 
			grid-row: 2; 
			grid-row-start: 1;
			grid-row-end: 3;
		}
		&:nth-child(2), &:nth-child(3) {
			grid-column: 2; 
		}
		&:nth-child(4), &:nth-child(5) {
			grid-column: 3; 
		}
		&:nth-child(4) {
			grid-row: 1;
		}
		&:before {
			background-color: rgba(181, 11, 11, 0.7);
			position: absolute; 
			content: ''; 
			width: 100%; 
			height: 100%;
			left: 0; 
			top: 0; 
			z-index: 0;
			opacity: 0;
		}
		&:hover {
			&:before {
				opacity: 1;
			}
			.content {
				opacity: 1;
			}
		}
		.content {
			opacity: 0;
			display: table;
			width: 100%;
			height: 100%;
			text-align: center;
			color: #fff;
			text-decoration: none;
			-webkit-transition: all 0.3s ease-out;  
			transition: all 0.3s ease-out; 
			position: relative;
			padding: 20px;

			h3 {
				position: relative;
				margin: 0;
				display: table-cell; 
				vertical-align: middle; 
				height: 100%; 
				&:before {
					position: absolute; 
					content: ''; 
					border: solid 8px #fff; 
					height: 100%;
					width: 100%; 
					left: 0; 
					top: 0;
				}
				&:after {
					position: absolute; 
					content: ''; 
					border-top: solid 3px green; 
					height: 100%;
				}
			}
		}
	}

}

.banner-intern {
	background-image: url('../img/bg-header.jpg'); 
	background-repeat: no-repeat;
	background-size: cover; 
	text-align: center; 
	height: 30vh;
	margin-bottom: 100px;
	display: table;
	width: 100%;
	.container {
		display: table-cell; 
		vertical-align: middle; 
		h2 {
			font-size: 70px;
			text-transform: uppercase;
			color: #fff;
			margin-top: 0;
			letter-spacing: 3px;
		}
	}

}

.contact-page {
	p {
		font-size: 20px;
	}
	.mail-contact-page {
		display: inline-block; 
		font-size: 20px; 
		color: #111111;
		word-break: break-all; 
	}
	.traco {
		position: relative; 
		&:before {
			position: absolute; 
			content: ''; 
			border-right: solid 1px #111111; 
			right: -10px;
			top: 0;
			height: 100%;
		}
	}
}

.page-contact-form {
	h3 {
		position: relative; 
		padding-left: 65px;
		color: $red-color; 
		margin-bottom: 40px;
		font-size: 22px;
		text-transform: uppercase;
		&:before {
			position: absolute; 
			content: ''; 
			background-image: url('../img/icon-fale-conosco-black.png'); 
			width: 55px; 
			height: 49px;
			top: -14px;
			left: 0;
		}
	}

	.form-group {
		margin-bottom: 0;
	}
	.row {
		margin-left: -5px;
		margin-right: -5px;
	}
	div[class*="col-sm-"] {
		padding-right: 5px;
		padding-left: 5px;
	}
	label {
		display: block;
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 8px;
		input, textarea {
			border-radius: 0;
			border-color: #a9a9a9;
			&:focus {
				border-color: #a9a9a9;
				outline: 0;
				box-shadow: none;
			}
		}
		input {
			height: 45px;
			line-height: 45px;
			padding: 0;
			padding-left: 20px;
			padding-right: 15px;
		}
		textarea {
			padding-top: 10px;
			padding-left: 20px;
		}
	}
	button[type="submit"] {
		width: 100%;
		background-color: transparent;
		box-shadow: none;
		border: solid 2px $red-color;
		color: $red-color;
		height: 60px;
		line-height: 60px;
		padding: 0;
		font-weight: 600;
		letter-spacing: 1px;
		box-shadow: none;
		-webkit-transition: all 0.3s ease-out;  
		        transition: all 0.3s ease-out; 
		&:hover {
			color: #fff; 
			background-color: $red-color;
		}
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #210a0a;
		font-size: 12px;
		font-weight: 400;
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: #210a0a;
		font-size: 12px;
		font-weight: 400;
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: #210a0a;
		font-size: 12px;
		font-weight: 400;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: #210a0a;
		font-size: 12px;
		font-weight: 400;
	}
}

.custom-select {
	width: 100%; 
	display: block; 
	.btn-select {
		border-radius: 0; 
		background-color: #fff; 
		height: 45px;
		box-shadow: none; 
		border: solid 1px #a9a9a9;
		&:hover {
			background-color: #fff;
		}
		&:hover, &:focus {
			border: solid 1px #a9a9a9;
			box-shadow: none;
		}
	}
	.bootstrap-select.form-control {
		&:focus {
			box-shadow: none; 
			border-color: #111;
		}
	}
}

// Custom Container {
.container {
    max-width: 1170px;
}

@media (min-width: 992px) {
	.container {
	    width: 100%;
	}
}

@media (min-width: 768px) {
	.container {
	    width: 100%;
	}
}

// Pagina de Erro
.erro-page {
	min-height: 100vh;
	background-image: url(../img/bg.png);
	background-color: #fff;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom right;
	padding-bottom: 20px;

	.image-exit {
		padding-top: 50px;
		img {
			max-height: 300px;
		}
	}

	h1 {
		font-size: 90px; 
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 0;
	}

	h2 {
		font-size: 50px; 
		text-transform: uppercase;
		color: #fff;
		font-weight: 400;
		margin-bottom: 30px;
		margin-top: 0;
	}

	a {
		display: inline-block;
		color: #fff;
		border: solid 1px #fff;
		font-size: 20px;
		font-weight: 600;
		padding: 16px 80px;
		text-decoration: none; 
		-webkit-transition: all 0.3s ease-out;  
		        transition: all 0.3s ease-out;
		&:hover {
			background-color: #fff;
			color: $red-color;
		}

	}
}

@media (max-width: 600px) {
	.erro-page {

		h1 {
			font-size: 30px; 
		}

		h2 {
			font-size: 30px; 
		}

		a {
			padding: 16px 30px;
		}
	}
}

// Helpers
.row.custom-padding {
	.col-md-4, .col-sm-6 {
		padding-left: 5px; 
		padding-right: 5px;
	}
}

.icon-facebook {
	color: #47619c;
	border-radius: 50%;
	border: solid #47619c 4px;
	width: 80px;
	height: 80px;
	display: inline-block;
	text-align: center;
	font-size: 40px;
	line-height: 80px;
	-webkit-transition: all 0.3s ease-out;  
	transition: all 0.3s ease-out; 
	&:hover {
		background-color: #47619c;
		color: #fff;
	}
}

.p-t-40 {
	padding-top: 40px; 
}

.p-b-40 {
	padding-bottom: 40px; 
}

.p-t-100 {
	padding-top: 100px; 
}

.p-b-100 {
	padding-bottom: 100px; 
}

.m-0-auto {
	margin: 0 auto;
}

.m-b-20 {
	margin-bottom: 20px;
}

.m-b-30 {
	margin-bottom: 30px;
}

.m-b-40 {
	margin-bottom: 40px;
}

.m-b-70 {
	margin-bottom: 70px;
}

.m-b-100 {
	margin-bottom: 100px;
}

.d-inline-block {
	display: inline-block; 
}

// Media Queries
@media(max-width: 1190px) {
	.wrapper {
		padding-left: 15px; 
		padding-right: 15px;
	}
}

@media(max-width: 991px) {
	.traco {
		&:before {
			content: none;
			display: none; 
		}
	}
	.m-b-15-sm {
		margin-bottom: 15px;
	}
}

@media(max-width: 768px) {
	.d-flex {
		display: block; 
	}

	.hide-xs {
		display: none;
	}

	.text-center-sm {
		text-align: center;
	}

	.bg-contato-home {
		img {
			margin-bottom: 0;			
		}

		h3 {
			margin: 0; 
			margin-bottom: 10px;
		}

		.icon-title-fale-conosco {
			margin-top: 30px;
			margin-bottom: 20px;
		}
	}

	.wrapper {
		display: block; 
		padding-right: 15px; 
		padding-left: 15px;
		.box {
			margin-bottom: 8px;
			display: table; 
		}
	}

	.navigation {
		ul {
			li {
				a {
					padding: 25px 0;
				}
			}
		}
	}
}

.btn-mobile {
	display: none; 
}

.navigation-mobile {
	display: none; 
}

@media(max-width: 600px) {
	// Helpers-Mobile 
	.p-t-mobile-30 {
		padding-top: 30px; 
	}
	header, body.intern header {
		padding-top: 0;
	}
	.no-banner {
	    min-height: 130px;
	}
	.navigation {
		display: none;
		ul {
			li {
				display: block;
				vertical-align: middle;
				width: 100%;
				text-align: center;
			}
		}
	}

	.navigation-mobile.active {
		display: block;
		text-align: center;
		position: relative; 
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 99;
			li {
				a {
					color: #fff;
					background-color: $red-color;
					display: block;
					padding: 15px 0;
					-webkit-transition: all 0.3s ease-out;  
					transition: all 0.3s ease-out; 
					text-decoration: none;
					&:hover {
						background-color: #d93b3c;
						color: #fff;
					}
				}
			}
		}
	}

	body.intern {
		.navigation {
			ul {
				li {
					a {
						color: #fff;
					}
				}
			}
		}
	} 
	.btn-mobile {
		display: block;
		text-align: center; 
		background-color: #fef4f5;
		padding: 4px 0;
		color: $red-color;
		font-size: 30px;
		&:hover, &:focus {
			color: $red-color;
		}
	}
	.banner-top {
	
		h1,h2 {
			font-size: 24px; 
		}
		p {
			font-size: 16px;
		}
		a.action {
			font-size: 14px;
			padding: 6px 10px;
			border: solid 4px;
		}
	}

	.facebook-section {
		text-align: center;
		.link-facebook {
			font-size: 16px;
		}
	}
	.section-title-white {
		font-size: 36px;
	}

	.section-title-red {
		font-size: 36px;
	}
	footer .footer-navigation {
		ul {
			li {
				display: block; 
				width: 100%;
			}
		}
	}
	// Helpers
	.p-t-100 {
		padding-top: 50px; 
	}

	.p-b-100 {
		padding-bottom: 50px; 
	}

	.m-b-100 {
		margin-bottom: 50px;
	}

	.banner-intern {
		margin-bottom: 30px;
		.container {
			h2 {
				font-size: 40px;
			}
		}
	}

}

@media (max-width: 400px) {
	.navigation.active {
		top: -78px;
	}
}



